import React from "react";

const ProfileInfo = ({ user }) => {
  
  const getStatusBadge = () => {
    if (user.blocked) {
      return (
        <span className="text-sm bg-red-200 text-red-800 px-2 py-1 rounded-full">
          Rejected
        </span>
      );
    }
    if (!user.approved) {
      return (
        <span className="text-sm bg-yellow-200 text-yellow-800 px-2 py-1 rounded-full">
          Awaiting Approval
        </span>
      );
    }
    return (
      <span className="text-sm bg-green-200 text-green-800 px-2 py-1 rounded-full">
        Approved
      </span>
    );
  };

  return (
    <div className="z-0 text-center">
      <img
        src={user?.photo_url}
        alt={user?.name}
        className="w-64 h-64 object-cover rounded-full mx-auto mt-4"
      />
      <h2 className="text-xl font-semibold mt-2">
        {user.first_name} {user.last_name}
      </h2>
      {/* Display the status badge */}
      <div className="mt-4">
        {getStatusBadge()}
      </div>
    </div>
  );
};

export default ProfileInfo;
