import React, { useState, useEffect } from "react";
import Modal from "../../../../modals/Modal";
import Table from "./Table";
import { adminURL } from "../../../../../utils/urls";
import ViewReasonButton from "./ViewReasonButton";
import BanButton from "./BanButton";

function BlockRequests() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockRequests, setBlockRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [banID, setBanID] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
    lastPage: 1,
    total: 0,
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [reasonVal, setReasonVal] = useState(selectedUser?.reason || "");

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async (page = 1) => {
    setLoading(true);
    try {
      const response = await adminURL.get(`reports?page=${page}`);

      const filteredData = response?.data?.data.filter((report) => {
        return report.reporter && report.reported && !report.reported.banned;
      });

      setBlockRequests(filteredData);
      setPagination({
        currentPage: response.data.current_page,
        lastPage: response.data.last_page,
        total: response.data.total,
        perPage: response.data.per_page,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const initialSortBy = [{ id: "serial_no", desc: false }];

  const columns = [
    {
      Header: "S/N",
      accessor: "serial_no",
      Cell: ({ row }) => (
        <span className="text-white font-medium">{row.index + 1}</span>
      ),
    },
    {
      Header: "Offender",
      accessor: (row) => (
        <div className="flex items-center space-x-4 capitalize">
          <section className="flex items-center justify-start space-x-4">
            <div className="avatar h-12 w-12">
              <img
                src={row?.reported?.photo_url}
                alt="Offender"
                className="rounded-full object-cover object-center h-full w-full"
              />
            </div>
            <span className="font-medium text-base text-slate-300 line-clamp-2">
              {row?.reported?.name}
            </span>
          </section>
        </div>
      ),
    },
    {
      Header: "Reported by",
      accessor: (row) => (
        <div className="flex items-center ml-10 space-x-4 capitalize">
          <section className="flex items-center justify-start space-x-4">
            <div className="avatar h-12 w-12">
              <img
                src={row?.reporter?.photo_url}
                alt="Reporter"
                className="rounded-full object-cover object-center h-full w-full"
              />
            </div>
            <span className="font-medium text-base text-slate-300 line-clamp-2">
              {row?.reporter?.name}
            </span>
          </section>
        </div>
      ),
    },
    {
      Header: "Reason",
      accessor: "reason",
      Cell: ({ value }) => <ViewReasonButton reason={value} />,
    },
    {
      Header: "Actions",
      accessor: (row) => (
        <div className="flex items-center justify-center space-x-4">
          <>
            <button
              className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded cursor-pointer"
              onClick={() => handleBlockClick(row)}
            >
              Ban
            </button>
          </>
        </div>
      ),
    },
  ];

  const handleBlockClick = (user) => {
    setSelectedUser(user);
    setBanID(user?.reported?.id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="w-full rounded-b-lg p-3 space-y-4">
      <div className="w-full overflow-x-auto">
        {loading ? (
          <div className="flex items-center justify-center w-full h-32">
            <div className="w-16 h-16 border-4 border-t-4 border-white border-solid rounded-full animate-spin"></div>
          </div>
        ) : blockRequests.length > 0 ? (
          <Table
            columns={columns}
            data={blockRequests}
            initialSortBy={initialSortBy}
            fullWidth={true}
            pagination={pagination}
            onPageChange={fetchReports}
          />
        ) : (
          <div className="text-center py-4 text-white text-xl">
            No reports or block requests
          </div>
        )}
      </div>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {selectedUser && (
          <div className="p-4 space-y-4 text-white">
            <h2 className="text-xl">Report {selectedUser?.reported?.name}</h2>
            <p>
              User{" "}
              <span className="text-amber-500">
                {selectedUser?.reported?.name}
              </span>{" "}
              has been reported to PlatinumFuse.
            </p>
            <div className="space-y-2">
              <label className="block">Reason for Block</label>
              <input
                type="text"
                value={reasonVal}
                onChange={(e) => setReasonVal(e.target.value)}
                className="w-full bg-gray-700 border border-white rounded p-2 outline-none"
                required
              />
            </div>
            <div className="flex justify-between space-x-4 mt-4">
              <button
                onClick={handleCloseModal}
                className="bg-white text-black py-2 px-4 rounded cursor-pointer"
              >
                Cancel
              </button>
              <BanButton id={banID} reason={reasonVal} />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default BlockRequests;
