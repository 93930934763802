import React from "react";

const UserImage = ({ user }) => {

  return (
    <div className="flex flex-col items-center space-y-4 ml-0 md:flex-row md:space-y-0 lg:ml:-10 xl:ml-20 md:space-x-8">
      <img
        src={user?.photo_url}
        alt={user?.name}
        className="w-24 h-24 rounded-full object-cover sm:w-32 sm:h-32 md:w-40 md:h-40"
      />
      <p className="text-lg capitalize sm:text-xl md:text-2xl lg:text-3xl font-semibold">
        {user?.name}
      </p>

    </div>
  );
};

export default UserImage;
