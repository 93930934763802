import React from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <button
      onClick={handleBackClick}
      className="flex items-center justify-center space-x-2 text-white bg-gray-800 hover:bg-gray-700 py-2 px-4 rounded-md 
                       focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
    >
      <MdArrowBack className="w-5 h-5" />
      <span>Back</span>
    </button>
  );
};

export default BackButton;
