import React, { useState } from "react";
import Schedule from "./schedule/Schedule";
import Slots from "./slots/Slots";
import { Link } from "react-router-dom";

const CoachMoreInfo = ({ user }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(null);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  return (
    <section className="flex flex-col items-center justify-center min-h-screen px-4 text-white">
      <p className="text-lg font-semibold mb-4 text-center">
        Selected Date & Time of Availability
      </p>
      <Schedule
        selectedDate={selectedDate}
        onDateChange={handleDateChange}
        setFirstDayOfMonth={setFirstDayOfMonth}
      />
      <section className="w-full max-w-4xl mt-6">
        <Slots
          firstDayOfMonth={firstDayOfMonth}
          selectedDate={selectedDate}
          userID={user.id}
        />
      </section>
      <section className="flex flex-col md:flex-row justify-center items-center gap-4 p-4">
        <Link
          to={`/dashboard/coaches/publications/${user.id}`}
          className="bg-amber-500 text-gray-900 py-2 px-4 rounded hover:bg-amber-600 transition"
        >
          Publications
        </Link>
        <Link
          to={`/dashboard/coaches/payments/${user.id}`}
          className="bg-amber-500 text-gray-900 py-2 px-4 rounded hover:bg-amber-600 transition"
        >
          Payments
        </Link>
      </section>
    </section>
  );
};

export default CoachMoreInfo;
