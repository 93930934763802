import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { adminURL } from "../utils/urls";
import Loader from "../components/loader/FetchLoader";
import Publications from "../components/singleCoaches/more/publications/Publications";
import UserImage from "../components/userImage/UserImage";

const CoachPublications = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const [totalPages, setTotalPages] = useState(1);

  // Fetch user and publications data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await adminURL.get(`users/${id}`);
        const publicationResponse = await adminURL.get(`publications?user_id=${id}`);
        const { data } = publicationResponse.data;

        setUser(userResponse.data.data?.user);
        
        // Calculate total pages based on all publications
        setTotalPages(Math.ceil(data.length / itemsPerPage));

        // Update publications for the current page
        const slicedData = data.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        );
        setPublications(slicedData);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load publications. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, currentPage]);

  // Handle navigation back
  const handleGoBack = () => {
    navigate(-1);
  };

  // Handle pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  // Render loading state or error message
  if (loading) {
    return <Loader text="Fetching Publications" />;
  }

  if (error) {
    return (
      <div className="text-red-500 text-center py-4">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <section>
      {/* Header Section */}
      <div className="w-full flex flex-col items-center text-center py-6 px-4 md:px-10">
        <header
          style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
          className="flex justify-center items-center py-6 px-4 rounded-md space-x-8 w-full max-w-[1020px]"
        >
          <h1 className="text-xl text-white">Coach Publications</h1>
        </header>
        
        {/* Back Button */}
        <div className="w-full max-w-[1020px] flex justify-end mt-4">
          <button
            onClick={handleGoBack}
            className="text-white bg-gray-700 hover:bg-gray-800 px-3 py-1 sm:px-4 sm:py-2 text-sm sm:text-base rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50"
          >
            &#8592; Back
          </button>
        </div>
      </div>

      {/* User Image Section */}
      <section className="px-4 lg:px-6 xl:px-10">
        <UserImage user={user} />
      </section>

      {/* Publications Section */}
      <Publications
        publications={publications}
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      {/* Pagination Controls */}
      <div className="flex justify-between mt-4 px-10 xl:px-20">
        {currentPage > 1 && (
          <button
            onClick={handlePrevPage}
            className="bg-gray-700 text-white py-2 px-4 rounded-md cursor-pointer"
          >
            Previous
          </button>
        )}
        {currentPage < totalPages && (
          <button
            onClick={handleNextPage}
            className="bg-gray-700 text-white py-2 px-4 rounded-md cursor-pointer"
          >
            Next
          </button>
        )}
      </div>
    </section>
  );
};

export default CoachPublications;