import React from "react";

const InputField = ({ label, type, value, onChange, error, no }) => {
  return (
    <div className="space-y-1">
      <label className="text-white capitalize">{label}</label>
      <input
        type={no ? "number" : type}
        value={value}
        onChange={onChange}
        className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 placeholder-gray-500"
        required
        style={no ? { MozAppearance: "textfield" } : {}}
      />
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  );
};

export default InputField;
