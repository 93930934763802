import React, { useState, useEffect } from "react";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStepContext } from "../../../context/stepContext";
import Icon from "../../../../assets/coachassets/images-videos/logo2.png";
import { baseURL } from "../../../../utils/urls";
import { toast } from "react-toastify";
import InputField from "../reuseable-components/InputField";
import { useNavigate } from "react-router-dom";
import DeviceModal from "./DeviceModal";

const CBOne = () => {
  const [showDeviceModal, setShowDeviceModal] = useState(false);

  const getInitialFormValues = () => {
    const savedValues = localStorage.getItem("coachFormValues");
    if (savedValues) {
      return JSON.parse(savedValues);
    }
    return {
      gender: null,
      birthday: "",
      levelOfEducation: "",
      institutionName: "",
      profilePhoto: null,
      phoneNumber: "",
      nin: "",
    };
  };

  const [formValues, setFormValues] = useState(getInitialFormValues());
  const [photoData, setPhotoData] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { handleStep } = useStepContext();

  const navigate = useNavigate();

  useEffect(() => {
    const deviceValue = localStorage.getItem("device");
    if (deviceValue === "1" || deviceValue === "true") {
      setShowDeviceModal(true);
    }
  }, []);

  useEffect(() => {
    const stepCompleted = localStorage.getItem("i");
    if (!stepCompleted || stepCompleted !== "true") {
      navigate("/coaches/signup");
      return;
    }
  }, [navigate]);

  useEffect(() => {
    localStorage.setItem("coachFormValues", JSON.stringify(formValues));
  }, [formValues]);

  const handleSelectGender = (gender) => {
    const genderValue =
      gender === "male" ? "M" : gender === "female" ? "F" : "";
    setFormValues((prev) => ({ ...prev, gender: genderValue }));
    setErrors((prev) => ({ ...prev, gender: null }));
  };

  const validateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    return monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ? age - 1
      : age;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    const phoneNumberPattern = /^[0-9]{11,}$/;
    if (!formValues.gender) newErrors.gender = "Gender is required";
    if (!formValues.birthday) {
      newErrors.birthday = "Birthday is required";
    } else if (validateAge(formValues.birthday) < 18) {
      newErrors.birthday = "You must be at least 18 years old to onboard";
    }
    if (!formValues.levelOfEducation)
      newErrors.levelOfEducation = "Level of education is required";
    if (!formValues.institutionName)
      newErrors.institutionName = "Institution name is required";
    if (!formValues.profilePhoto)
      newErrors.profilePhoto = "Profile photo is required";
    if (!formValues.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    } else if (!phoneNumberPattern.test(formValues.phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be at least 11 digits";
    }
    if (!formValues.nin) newErrors.nin = "NIN is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Check if step is already completed
      const stepOneCompleted =
        localStorage.getItem("coachStepOneCompleted") === "true";

      if (stepOneCompleted) {
        handleStep(2);
        return;
      }

      setIsLoading(true);

      const payload = new FormData();
      payload.append("gender", formValues.gender);
      payload.append("dob", formValues.birthday);
      payload.append("education", formValues.levelOfEducation);
      payload.append("institute", formValues.institutionName);
      payload.append("photo", photoData);
      payload.append("phone", formValues.phoneNumber);
      payload.append("nin", formValues.nin);
      payload.append("role", "coach");
      payload.append("_method", "PUT");
      payload.append("meta[onboarding][complete]", 0);
      payload.append("meta[onboarding][step]", 2);

      const coachToken = localStorage.getItem("coachToken");
      const id = localStorage.getItem("browsercid");

      try {
        const { data } = await baseURL.post(`users/${id}`, payload, {
          headers: {
            Authorization: `Bearer ${coachToken}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (data?.errors) {
          toast.warning(data.message);
        } else {
          localStorage.setItem("coachStepOneCompleted", "true");
          handleStep(2);
        }
      } catch (error) {
        alert("Network error. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClearError = (field) => {
    setErrors((prev) => ({ ...prev, [field]: null }));
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setErrors((prev) => ({
          ...prev,
          profilePhoto: "File size should be less than or equal to 5MB",
        }));
      } else {
        setPhotoData(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setFormValues((prev) => ({
            ...prev,
            profilePhoto: e.target.result,
          }));
        };
        reader.readAsDataURL(file);
        handleClearError("profilePhoto");
      }
    }
  };

  return (
    <>
      {showDeviceModal && (
        <DeviceModal setShowDeviceModal={setShowDeviceModal} />
      )}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-transparent bg-opacity-50">
          <div className="bg-black p-6 rounded-lg text-center">
            <img
              src={Icon}
              alt="Loading..."
              className="w-16 h-16 mx-auto mb-4"
            />
            <p className="text-lg font-bold text-white">
              Thank you for your patience.
            </p>
            <p className="mt-2 text-white">
              One more step remaining to complete your onboarding process.
            </p>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6 p-4">
        <div className="space-y-6 md:space-y-0 gap-[5rem] md:grid md:grid-cols-2 xl:gap-[10rem]">
          <div className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              {["Male", "Female"].map((gender) => (
                <div
                  key={gender}
                  className={`px-3 py-2 text-white rounded-md text-center cursor-pointer ${
                    formValues.gender === (gender === "Male" ? "M" : "F")
                      ? "bg-blue-500"
                      : "bg-gray-700"
                  }`}
                  onClick={() => handleSelectGender(gender.toLowerCase())}
                >
                  {gender}
                </div>
              ))}
            </div>

            {errors.gender && (
              <p className="text-red-500 text-sm">{errors.gender}</p>
            )}

            <InputField
              label="Date of Birth"
              type="date"
              value={formValues.birthday}
              onChange={(e) => {
                setFormValues((prev) => ({
                  ...prev,
                  birthday: e.target.value,
                }));
                handleClearError("birthday");
              }}
              error={errors.birthday}
            />

            <InputField
              label="Phone Number"
              type="text"
              no={true}
              value={formValues.phoneNumber}
              onChange={(e) => {
                setFormValues((prev) => ({
                  ...prev,
                  phoneNumber: e.target.value,
                }));
                handleClearError("phoneNumber");
              }}
              error={errors.phoneNumber}
            />

            <InputField
              label="NIN"
              type="text"
              no={true}
              value={formValues.nin}
              onChange={(e) => {
                setFormValues((prev) => ({
                  ...prev,
                  nin: e.target.value,
                }));
                handleClearError("nin");
              }}
              error={errors.nin}
            />

            <div className="space-y-1">
              <label className="text-white capitalize">
                Level of Education
              </label>
              <select
                value={formValues.levelOfEducation}
                onChange={(e) => {
                  setFormValues((prev) => ({
                    ...prev,
                    levelOfEducation: e.target.value,
                  }));
                  handleClearError("levelOfEducation");
                }}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2"
                required
              >
                <option value="">Select</option>
                <option value="hsd">High School</option>
                <option value="asd">Associate's Degree</option>
                <option value="bsc">Bachelor's Degree</option>
                <option value="msc">Master's Degree</option>
                <option value="doc">Doctorate</option>
              </select>
              {errors.levelOfEducation && (
                <p className="text-red-500 text-sm">
                  {errors.levelOfEducation}
                </p>
              )}
            </div>

            <InputField
              label="Name of Institution"
              type="text"
              value={formValues.institutionName}
              onChange={(e) => {
                setFormValues((prev) => ({
                  ...prev,
                  institutionName: e.target.value,
                }));
                handleClearError("institutionName");
              }}
              error={errors.nin}
            />
          </div>

          <div className="space-y-4">
            <div className="text-center">
              <label htmlFor="profile-photo" className="cursor-pointer">
                <div className="border-primary bg-primary border-2 rounded-full mt-4 w-32 h-32 m-auto">
                  {formValues.profilePhoto ? (
                    <img
                      src={formValues.profilePhoto}
                      alt="Profile"
                      className="w-full h-full rounded-full"
                    />
                  ) : (
                    <div className="flex justify-center items-center w-full h-full">
                      <FontAwesomeIcon icon={faCamera} className="text-5xl" />
                    </div>
                  )}
                </div>
                <p className="max-w-sm mt-4 py-2 px-4 mx-auto border-2 border-primary rounded-lg cursor-pointer text-sm font-bold uppercase bg-primary">
                  Upload Photo
                </p>
              </label>
              <input
                id="profile-photo"
                name="profilePhoto"
                type="file"
                accept="image/*"
                onChange={handlePhotoUpload}
                className="hidden"
              />
              {errors.profilePhoto && (
                <p className="text-red-500 text-sm">{errors.profilePhoto}</p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8 flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-primary font-bold text-black rounded-lg hover:bg-amber-700"
          >
            Save & Continue
          </button>
        </div>
      </form>
    </>
  );
};

export default CBOne;
