import React, { useState, useEffect } from "react";
import PublishItem from "./PublishItem";
import { adminURL } from "../../../../utils/urls";
import Loader from "../../../../components/loader/FetchLoader";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { useNavigate } from "react-router-dom";

const Publish = () => {
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await adminURL.get(`publications`);
      const { data } = response.data;
      setPublications(data);
      setTotalPages(Math.ceil(data.length / 4));
    } catch (error) {
      console.error("Error fetching publications:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action is irreversible!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      background: "#1e293b",
      color: "#ffffff",
      iconColor: "#f87171",
      customClass: {
        popup: "swal2-boxed",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        adminURL
          .delete(`publications/${id}`)
          .then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "The publication has been deleted.",
              icon: "success",
              background: "#1e293b",
              color: "#ffffff",
            }).then(() => {
              fetchData();
            });
          })
          .catch(() => {
            Swal.fire({
              title: "Error!",
              text: "Something went wrong. Please try again.",
              icon: "error",
              background: "#1e293b",
              color: "#ffffff",
            });
          });
      }
    });
  };

  const handleEdit = (id) => {
    navigate(`/coaches/dashboard/publication/upload/delete/${id}`);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };


  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * 4;
    const endIndex = startIndex + 4;
    return publications.slice(startIndex, endIndex);
  };

  return (
    <div className="p-4">
      {loading ? (
        <Loader text="fetching publications" />
      ) : publications.length === 0 ? (
        <div className="flex flex-col items-center justify-center text-gray-400 py-8">
          <p className="text-lg">No publications have been published yet 😔</p>
        </div>
      ) : (
        <>
          {getPaginatedData().map((publication, index) => (
            <PublishItem
              key={publication.id}
              publication={publication}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              isLast={index === getPaginatedData().length - 1}
            />
          ))}
          <div className="flex justify-between mt-4">
            {currentPage > 1 && (
              <button
                onClick={handlePrevPage}
                className="bg-gray-700 text-white py-2 px-4 rounded-md cursor-pointer"
              >
                Previous
              </button>
            )}
            {currentPage < totalPages && (
              <button
                onClick={handleNextPage}
                className="bg-gray-700 text-white py-2 px-4 rounded-md cursor-pointer"
              >
                Next
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Publish;