import React from "react";
import Highlights from "./Highlights/Highlights";

const Payments = () => {
  return (
    <section className="">
      <Highlights />
    </section>
  );
};

export default Payments;
