import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { validateStep1 } from "../cSignup/utils";
import Step1 from "../cSignup/Step1";
import { baseURL } from "../../../utils/urls";
import { toast } from "react-toastify";
import Icon from "../../../assets/coachassets/images-videos/logo2.png";

const SignupForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,

    formState: { errors },
    getValues,
    setError,
    clearErrors,
    register,
  } = useForm();

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const isValid = await validateStep1(getValues, setError, clearErrors);
    if (isValid) {
      const payload = {
        email: formData.emailAddress,
        password: formData.password,
        first_name: formData.firstName,
        last_name: formData.lastName,
        password_confirmation: formData.repeatPassword,
        role: "coach",
        meta: {
          onboarding: { complete: false, step: 1 },
        },
      };

      try {
        const { data } = await baseURL.post("register", payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (data?.errors) {
          toast.warning(data.message);
        } else {
          // console.log(data?.data?.user.id);
          const coachToken = data?.data?.token;
          localStorage.setItem("coachToken", coachToken);
          localStorage.setItem("browsercid", data?.data?.user.id);
          localStorage.setItem("i", true);
          navigate("/coaches/signup?basic_details=true");
        }
      } catch (error) {
        toast.error("An error occurred during registration");
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-[80%]">
      <div className="!mb-3">
        <p className="text-base text-white font-bold tablet:text-primary">
          Please enter your details
        </p>
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Step1
            control={control}
            errors={errors}
            register={register}
            showPassword={showPassword}
            togglePasswordVisibility={() => setShowPassword(!showPassword)}
            clearErrors={clearErrors}
          />
          <div className="w-full mt-10 text-lg flex flex-col items-center space-y-3">
            <button className="w-full bg-amberBg font-bold py-3 px-4 rounded-lg hover:bg-amber-500 transition duration-300">
              Sign up
            </button>
            <div className="text-center">
              <p className="text-white">
                Already a member?{" "}
                <Link
                  to="/login"
                  className="text-amberBg hover:underline cursor-pointer"
                >
                  Log in
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>

      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-transparent bg-opacity-50">
          <div className="bg-black p-6 rounded-lg text-center">
            <img
              src={Icon}
              alt="Loading..."
              className="w-16 h-16 mx-auto mb-4"
            />
            <p className="text-lg font-bold text-white">
              Submitting your details...
            </p>
            <p className="mt-2 text-white">
              You will be redirected to another page to complete your signup
              process.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignupForm;
