import React, { useState } from "react";
import { adminURL } from "../../utils/urls";
import Loader from "../loader/FetchLoader";
import ProfileInfo from "./small/ProfileInfo";
import ActionButtons from "./small/ActionButtons";
import DeclineReasonModal from "./small/DeclineReasonModal";
import CoachMoreInfo from "./more/CoachMoreInfo";

const Csmall = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [showDeclineReason, setShowDeclineReason] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const isApproved = user.approved;

  const handleApproval = async (approved) => {
    setLoading(true);
    try {
      await adminURL.patch(`users/${user.id}`, { approved: true });
      window.location.reload();
    } catch (error) {
      console.error("Error approving user:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async () => {
    setLoading(true);
    try {
      await adminURL.patch(`users/${user.id}`, {
        approved: false,
        meta: {
          decline_reason: declineReason,
        },
      });
      window.location.reload();
    } catch (error) {
      console.error("Error declining user:", error);
    } finally {
      setLoading(false);
      setShowDeclineReason(false);
    }
  };

  return (
    <>
      <section className="relative p-4 space-y-4 flex flex-col items-center">
        {loading && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
            <Loader text="Processing..." />
          </div>
        )}

        <ProfileInfo user={user} />

        <ActionButtons
          isApproved={isApproved}
          loading={loading}
          handleApproval={handleApproval}
          setShowDeclineReason={setShowDeclineReason}
        />

        <div className="space-y-1 mt-4 w-full max-w-xs z-0">
          <label className="text-white">Telephone Number</label>
          <input
            type="text"
            value={user?.phone}
            readOnly
            className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
          />
        </div>

        <DeclineReasonModal
          showDeclineReason={showDeclineReason}
          declineReason={declineReason}
          setDeclineReason={setDeclineReason}
          loading={loading}
          handleDecline={handleDecline}
          setShowDeclineReason={setShowDeclineReason}
        />
      </section>
      {isApproved && (
        <section>
          <CoachMoreInfo user={user} />
        </section>
      )}
    </>
  );
};

export default Csmall;