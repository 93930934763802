import React, { useEffect } from "react";
import Payments from "../components/singleCoaches/more/coachPayments/Payments";
import { useNavigate } from "react-router-dom";
import PaymentTables from "../components/singleCoaches/more/coachPayments/paymentTables/PaymentTables";

const CoachPayment = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <section>
      <div className="w-full flex flex-col items-center text-center py-6 px-4 sm:px-6 md:px-10">
        <header
          style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
          className="flex justify-center items-center py-6 px-4 sm:px-6 md:px-10 rounded-md space-x-8 w-full max-w-[1020px]"
        >
          <h1 className="text-xl text-white">Payments</h1>
        </header>
        <div className="w-full max-w-[1020px] flex justify-end mt-4">
          <button
            onClick={handleGoBack}
            className="text-white bg-gray-700 hover:bg-gray-800 px-3 py-1 sm:px-4 sm:py-2 text-sm sm:text-base rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50"
          >
            &#8592; Back
          </button>
        </div>
      </div>
      <section className="py-8">
        <Payments />
      </section>
      <section className="py-8">
        <PaymentTables />
      </section>
    </section>
  );
};

export default CoachPayment;
