import React from "react";

const DeviceModal = ({ setShowDeviceModal }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-xl font-bold mb-4 text-white">Complete Your Profile</h2>
        <p className="text-white mb-4">
          Please complete your signup process to access all features and content.
        </p>
        <button
          onClick={() => setShowDeviceModal(false)}
          className="bg-primary text-black px-4 py-2 rounded-lg hover:bg-amber-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default DeviceModal;