import React, { useState, useEffect } from "react";
import { useStepContext } from "../../../context/stepContext";
import CBFiles from "./cbfiles/CBFiles";
import SignupModal from "../../modal/SignupModal";
import { toast } from "react-toastify";
import { baseURL } from "../../../../utils/urls";
import { nigeriaCities } from "../utils";
import { useNavigate } from "react-router-dom";
import DeviceModal from "./DeviceModal";

const CBtwo = () => {
  const navigate = useNavigate();
  const [showDeviceModal, setShowDeviceModal] = useState(false);
  const [toBack, setToBack] = useState(false);

  useEffect(() => {
    const stepOneCompleted = localStorage.getItem("coachStepOneCompleted");
    if (!stepOneCompleted || stepOneCompleted !== "true") {
      navigate("/coaches/signup?basic_details=true&step=1");
      return;
    }
  }, [navigate]);

  const getInitialFormValues = () => {
    const savedValues = localStorage.getItem("coachFormValuesStepTwo");
    return savedValues
      ? JSON.parse(savedValues)
      : {
          speciality: "",
          linkedin: "",
          city: "",
        };
  };

  const [formValues, setFormValues] = useState(getInitialFormValues());
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { handleStep } = useStepContext();

  useEffect(() => {
    const deviceValue = localStorage.getItem("device");
    if (deviceValue === "1" || deviceValue === "true") {
      setShowDeviceModal(true);
      setToBack(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("coachFormValuesStepTwo", JSON.stringify(formValues));
  }, [formValues]);

  const validateLinkedIn = (linkedin) => {
    const regex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|profile)\/[A-Za-z0-9_-]+\/?$/;
    return regex.test(linkedin);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formValues.speciality) newErrors.speciality = "Speciality is required";
    if (!formValues.linkedin) newErrors.linkedin = "LinkedIn is required";
    else if (!validateLinkedIn(formValues.linkedin))
      newErrors.linkedin = "Invalid LinkedIn web URL";
    if (!formValues.city) newErrors.city = "City is required";
    if (files.length === 0) newErrors.resume = "Resume is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("occupation", formValues.speciality);
      formData.append("linkedin", formValues.linkedin);
      formData.append("city", formValues.city);
      formData.append("_method", "PUT");
      formData.append("meta[onboarding][complete]", 1);
      formData.append("meta[onboarding][step]", 3);
      if (files.length > 0) {
        formData.append("cv", files[0]);
      }

      const coachToken = localStorage.getItem("coachToken");
      const id = localStorage.getItem("browsercid");

      try {
        const { data } = await baseURL.post(`users/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${coachToken}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (data?.errors) {
          toast.warning(data.message);
        } else {
          localStorage.removeItem("coachFormValuesStepTwo");
          localStorage.removeItem("coachStepOneCompleted");
          localStorage.removeItem("coachFormValues");
          setIsModalOpen(true);
        }
      } catch (error) {
        toast.error("Network error. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleInputChange = (field, value) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  return (
    <>
      {showDeviceModal && (
        <DeviceModal setShowDeviceModal={setShowDeviceModal} />
      )}
      <form onSubmit={handleSubmit} className="space-y-6 p-4">
        <div className="space-y-6 md:space-y-0 md:grid md:grid-cols-2 md:gap-[10rem]">
          <div className="space-y-4">
            <div className="space-y-1">
              <label className="text-white capitalize">Speciality</label>
              <input
                type="text"
                value={formValues.speciality}
                onChange={(e) =>
                  handleInputChange("speciality", e.target.value)
                }
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2"
                placeholder="Accountant"
                required
              />
              {errors.speciality && (
                <p className="text-red-500 text-sm">{errors.speciality}</p>
              )}
            </div>

            <div className="space-y-1">
              <label className="text-white capitalize">LinkedIn</label>
              <input
                type="text"
                value={formValues.linkedin}
                onChange={(e) => handleInputChange("linkedin", e.target.value)}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2"
                placeholder="https://www.linkedin.com/in/john-doe"
                required
              />
              {errors.linkedin && (
                <p className="text-red-500 text-sm">{errors.linkedin}</p>
              )}
            </div>

            <div className="space-y-1">
              <label className="text-white capitalize">City</label>
              <select
                value={formValues.city}
                onChange={(e) => handleInputChange("city", e.target.value)}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2"
                required
              >
                <option value="">Select your city</option>
                {nigeriaCities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
              {errors.city && (
                <p className="text-red-500 text-sm">{errors.city}</p>
              )}
            </div>
          </div>

          <div className="space-y-4">
            <CBFiles text="Upload resume" setFiles={setFiles} />
            {errors.resume && (
              <p className="text-red-500 text-sm">{errors.resume}</p>
            )}
          </div>
        </div>

        <div className="mt-8 flex space-x-10 justify-end">
          {!toBack && (
            <button
              type="button"
              onClick={() => handleStep(1)}
              className="px-8 py-2 md:px-16 md:py-2 bg-transparent border border-white font-bold text-white rounded-lg hover:text-black hover:bg-primary hover:border-primary"
            >
              Back
            </button>
          )}

          <button
            type="submit"
            className="px-8 py-2 md:px-16 md:py-2 bg-primary font-bold text-black rounded-lg hover:bg-amber-700"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
      <SignupModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);

          localStorage.clear();
        }}
      />
    </>
  );
};

export default CBtwo;
