import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { X } from "lucide-react";

const Summary = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("isFirstVisitSession") !== "false";
    if (isFirstVisit) {
      setIsVisible(true);
      localStorage.setItem("isFirstVisitSession", "false");
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    isVisible && (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          className="bg-gray-800 border border-gray-700 rounded-lg shadow-lg p-4 sm:p-6 w-full max-w-lg mx-auto overflow-y-auto max-h-[90vh]"
        >
          <div className="flex justify-between items-center">
            <h3 className="font-semibold text-lg text-white">How to Use This Page</h3>
            <button onClick={handleClose} className="text-white">
              <X size={20} />
            </button>
          </div>
          <div className="mt-4 space-y-4 text-gray-300 text-sm sm:text-base">
            <p>
              To add slots, click on <strong>Add Slots</strong> on the right side of the slot box.
              A modal will pop up where you can select the time hourly. Any minutes entered will be converted
              to hourly (e.g., 8:30 AM - 9:30 AM will be converted to 8:00 AM - 9:00 AM).
            </p>
            <p>
              To add future slots, utilize the calendar on the left side. You can select a date and add multiple
              slots or available slots for the day, week, or month. Slots for a selected date will be listed in the
              slots box.
            </p>
            <p>Have a great time managing your sessions!</p>
          </div>
        </motion.div>
      </div>
    )
  );
};

export default Summary;