import React, { useEffect } from "react";
import Cdisplay from "./Cdisplay";
import BackButton from "../back-button/BackButton";

const Sc = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <section className="flex flex-col items-center w-full">
      <div className="w-full flex justify-center text-center py-6">
        <header
          style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
          className="flex justify-center items-center py-6 px-10 rounded-md space-x-8 w-full max-w-[1020px]"
        >
          <h1 className="text-xl text-white">Coaches Verification</h1>
          <BackButton />
        </header>
      </div>

      <Cdisplay />
    </section>
  );
};

export default Sc;
