import React from "react";
import PublishItem from "../../../../coaches-platform/components/cpublication/publications/PublishItem";

const Publications = ({
  publications,

}) => {
  return (
    <section className="max-w-4xl mx-auto py-4 px-10">
      {publications.length > 0 ? (
        <div>
          {publications.map((publication, index) => (
            <PublishItem
              key={publication.id}
              publication={publication}
              isLast={index === publications.length - 1}
              adminView={true}
            />
          ))}


        </div>
      ) : (
        <div className="text-center">No publications found.</div>
      )}
    </section>
  );
};

export default Publications;
