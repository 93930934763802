import React from "react";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { adminURL } from "../../../../utils/urls";

const fetchSlots = async (userID, startDate, endDate) => {
  const response = await adminURL.get(
    `users/${userID}/slots?starts_at=${startDate}&ends_at=${endDate} 23:59:59`
  );
  return response.data;
};

const Slots = ({ userID }) => {
  const startDate =
    localStorage.getItem("selectedDate") ||
    new Date().toISOString().split("T")[0];

  const endDate =
    localStorage.getItem("selectedDate") ||
    new Date().toISOString().split("T")[0];

  const { data, error, isLoading } = useQuery({
    queryKey: ["slots", userID, startDate, endDate],
    queryFn: () => fetchSlots(userID, startDate, endDate),
    enabled: !!userID && !!startDate && !!endDate,
    refetchInterval: 10000,
  });

  return (
    <div className="border border-amber-500 shadow-xl bg-black p-4 w-full max-w-2xl rounded-md lg:max-w-4xl">
      <div className="text-white flex justify-between items-center mb-2">
        <div className="flex flex-col">
          <span className="text-gray-300 text-sm font-light">Slots</span>
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-40">
          <motion.div
            className="bg-amber-500 p-3 rounded-full"
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          >
            <div className="loader border-t-transparent border-amber-500 border-4 border-solid rounded-full w-8 h-8" />
          </motion.div>
        </div>
      ) : error ? (
        <div className="text-red-500 flex justify-center items-center h-40">
          <span>😞 Oops! An error occurred. It’s not your fault.</span>
        </div>
      ) : (
        <div className="text-white flex flex-wrap gap-2">
          {data?.data?.length > 0 ? (
            data?.data.map((slot, index) => (
              <motion.div
                key={index}
                className={`flex items-center justify-between shadow-md rounded-md p-2 mb-1 ${
                  slot.booked ? "bg-red-500 text-white" : "bg-white text-black"
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <div>{slot.period_for_humans}</div>
              </motion.div>
            ))
          ) : (
            <div className="text-gray-400 flex items-center gap-2">
              No slots available <span>😴</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Slots;
