import { useLocation, useNavigate } from "react-router-dom";
import { useStepContext } from "../../../context/stepContext";
import { useEffect, useRef, useLayoutEffect } from "react";

export const StepUrlManager = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentStep, setCurrentStep } = useStepContext();
  const lastUrlStep = useRef(null);
  const isInitialMount = useRef(true);

  useLayoutEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const stepParam = queryParams.get("step");

    if (stepParam && stepParam !== 'show') {
      const stepNumber = parseInt(stepParam, 10);
      if (!isNaN(stepNumber) && stepNumber !== currentStep) {
        lastUrlStep.current = stepParam;
        setCurrentStep(stepNumber);
      }
    } else if (queryParams.get("basic_details") === "true" && !stepParam) {
      updateUrlWithStep(1);
    }

    isInitialMount.current = false;
    // eslint-disable-next-line 
  }, []);

  
  useEffect(() => {
    if (!isInitialMount.current) {
      const queryParams = new URLSearchParams(location.search);
      const stepParam = queryParams.get("step");

      if (stepParam && stepParam !== 'show' && stepParam !== lastUrlStep.current) {
        const stepNumber = parseInt(stepParam, 10);
        if (!isNaN(stepNumber) && stepNumber !== currentStep) {
          lastUrlStep.current = stepParam;
          setCurrentStep(stepNumber);
        }
      }
    }
    // eslint-disable-next-line 
  }, [location.search]);

 
  useEffect(() => {
    if (!isInitialMount.current && currentStep.toString() !== lastUrlStep.current) {
      updateUrlWithStep(currentStep);
    }
    // eslint-disable-next-line 
  }, [currentStep]);

  const updateUrlWithStep = (step) => {
    const queryParams = new URLSearchParams(location.search);
    
    if (queryParams.get("basic_details") === "true") {
      queryParams.set("step", step.toString());
      lastUrlStep.current = step.toString();
      navigate(`?${queryParams.toString()}`, { replace: true });
    }
  };

  return null;
};