import React from "react";
import { motion } from "framer-motion";

const ActionButtons = ({
  isApproved,
  loading,
  handleApproval,
  setShowDeclineReason,
}) => {
  return (
    <div className="flex justify-center space-x-4 mt-4 z-0">
      <button
        className={`bg-green-600 ${
          isApproved ? "opacity-50 cursor-not-allowed" : "hover:bg-green-700"
        } text-black font-bold py-2 px-4 rounded`}
        onClick={!isApproved ? () => handleApproval(true) : null}
        disabled={loading || isApproved}
      >
        Accept Profile
      </button>
      <motion.button
        className={`${
          !isApproved || isApproved ? "bg-red-600 hover:bg-red-700" : "bg-gray-600"
        } text-white font-bold py-2 px-4 rounded border border-white`}
        disabled={loading}
        onClick={() => setShowDeclineReason(true)}
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        Decline Profile
      </motion.button>
    </div>
  );
};

export default ActionButtons;