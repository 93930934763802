import React from "react";
import { motion } from "framer-motion";

const DeclineReasonModal = ({
  showDeclineReason,
  declineReason,
  setDeclineReason,
  loading,
  handleDecline,
  setShowDeclineReason,
}) => {
  if (!showDeclineReason) return null;

  return (
    <motion.div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-20"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full">
        <h3 className="text-white text-lg mb-4">Reason for Declining</h3>
        <textarea
          className="w-full h-32 p-2 bg-gray-700 text-white rounded-lg focus:outline-none"
          placeholder="Enter the reason for declining..."
          value={declineReason}
          onChange={(e) => setDeclineReason(e.target.value)}
          disabled={loading}
        />
        <div className="flex justify-end mt-4 space-x-2">
          <button
            className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded"
            onClick={() => setShowDeclineReason(false)}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded"
            onClick={handleDecline}
            disabled={loading || !declineReason.trim()}
          >
            Confirm
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default DeclineReasonModal;
